import React from "react";

import "./App.css";
import Properties from "./Properties";
import Home from "./Home";
import About from "./About";
import Demo from "./Demo";
import NotFound from "./NotFound";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Property from "./Property";
import Map from "./Map";
import Calculate from "./Calculate";
import Dashboard from "./Dashboard";
import SoldMap from "./SoldMap";
import DataPage from "./SoldStatistics/DataPage";
import Charts from "./SoldStatistics/Charts";
import Query from "./SoldStatistics/Query";

// import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import Navigation from "./Navigation/Navigation";
import SoldStatistics from "./SoldStatistics";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
export function getApiDomain() {
  return process.env.REACT_APP_API_URL;
}
export function getSeafowlURL() {
  return process.env.REACT_APP_SEAFOWL_URL;
}

export function getWebsiteDomain() {
  return process.env.REACT_APP_WEBSITE_URL;
}

export function getMapBoxToken() {
  return process.env.REACT_APP_MAPBOX_TOKEN;
}

export function getPropertyUrl() {
  return process.env.REACT_APP_PROPERTY_URL;
}
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Navigation />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="property/:propertyId" element={<Property />} />
            <Route path="map" element={<Map />} />
            <Route path="calculate" element={<Calculate />} />
            <Route path="" element={<Properties />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/sold" element={<SoldStatistics />} />
          <Route path="/sold/map" element={<SoldMap />} />
          <Route path="/sold/data" element={<DataPage />} />
          <Route path="/sold/charts" element={<Charts />} />
          <Route path="/sold/query" element={<Query />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
