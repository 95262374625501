import React from "react";

import AddProperty from "./AddProperty";
import LoadingAnimation from "../LoadingAnimation";

import { useQuery } from "react-query";
import CardArea from "./CardArea";
import HelpText from "./HelpText";
import HideSold from "./HideSold";
import { Toaster } from "react-hot-toast";
import { useOutletContext } from "react-router-dom";
import TableView from "./TableView";
import PropertyTable from "./PropertyTable";
import SearchStreet from "./SearchStreet";
export default function Properties() {
  const client = useOutletContext();
  const [hideRemoved, setHideRemoved] = React.useState(false);
  const [showTableView, setShowTableView] = React.useState(false);
  const [searchStreet, setSearchStreet] = React.useState("");
  const { isLoading, error, data } = useQuery(["allProperties"], () =>
    client.Records.getFullList("user_properties", 200 /* batch size */, {
      sort: "-created",
      expand: "property.first_image",
      filter: "",
    })
  );

  if (isLoading) return <LoadingAnimation />;

  if (error) return "An error has occurred: " + error.message;
  let allProperties = [];
  let nonRemovedProperties = [];
  if (data != null) {
    data.forEach((object, index) => {
      let relationId = object.id;
      let property = object["@expand"]["property"];
      if (property.removed != 1) {
        nonRemovedProperties.push({
          key: index,
          property: property,
          relationId: relationId,
        });
      }
      allProperties.push({
        key: index,
        property: property,
        relationId: relationId,
      });
    });
  }
  if (searchStreet != "") {
    allProperties = allProperties.filter((property) =>
      property.property.street.toLowerCase().includes(searchStreet.toLowerCase())
    );
  }

  let shownProperties = hideRemoved ? nonRemovedProperties : allProperties;
  let tableProperties = [];

  if (shownProperties != null) {
    shownProperties.forEach((object) => {
      let prop_status = "Till salu";
      if (object.property.closing_price != -1 && object.property.removed) {
        prop_status = "Såld";
      } else if (object.property.closing_price == -1 && object.property.removed) {
        prop_status = "Borttagen";
      }
      let modifiedProperty = object.property;
      modifiedProperty.status = prop_status;
      modifiedProperty.relationId = object.relationId;
      modifiedProperty.sqm_closing_price = Math.round(
        object.property.closing_price / object.property.sqm_area
      );
      tableProperties.push(modifiedProperty);
    });
  }
  return (
    <div>
      {/* {JSON.stringify(data)} */}
      <Toaster position="top-right" />
      <AddProperty />
      <HelpText startGuideState={data.length == 0 ? true : false} />
      <div className="grid grid-cols-2 container mx-auto max-w-3xl px-1 text-center mb-2">
        <HideSold hideRemoved={hideRemoved} setHideRemoved={setHideRemoved} />
        <TableView showTableView={showTableView} setShowTableView={setShowTableView} />
      </div>
      <SearchStreet searchStreet={searchStreet} setSearchStreet={setSearchStreet} />
      {showTableView ? (
        <PropertyTable data={tableProperties} />
      ) : (
        <CardArea objects={shownProperties} />
      )}
    </div>
  );
}
