import React, { useState } from "react";

import Table from "./Table";
import Interest from "./Interest";
import Amortization from "./Amortization";

function calcLoan(price, downPayment) {
  return Math.max(price - downPayment, 0);
}

function calcInterest(price, downPayment, interest) {
  return calcLoan(price, downPayment) * interest;
}

function calcInterestPerMonth(price, downPayment, interest) {
  return Math.round(calcInterest(price, downPayment, interest) / 12);
}

function calcEffectiveInterestRate(price, downPayment, interest, persons) {
  let totInterest = calcInterest(price, downPayment, interest);
  let firstdeduction = 100000 * persons;
  if (totInterest > firstdeduction) {
    let deduction = firstdeduction * 0.3 + (totInterest - firstdeduction) * 0.21;
    return deduction / totInterest;
  } else {
    return 0.3;
  }
}

function calcInterestDeduction(price, downPayment, interest, persons) {
  let totInterest = calcInterest(price, downPayment, interest);
  let deduction = totInterest * calcEffectiveInterestRate(price, downPayment, interest, persons);
  return Math.round(deduction);
}
function calcInterestDeductionPerMonth(price, downPayment, interest, persons) {
  return Math.round(calcInterestDeduction(price, downPayment, interest, persons) / 12);
}

function calcLoanRatio(price, downPayment) {
  return Math.round((calcLoan(price, downPayment) * 1000) / price) / 10;
}

function calcIncomeRatio(price, downPayment, income) {
  let loan = calcLoan(price, downPayment);
  return Math.round((loan / income) * 100) / 100;
}

function calcExtraAmortization(price, downPayment, income) {
  if (calcIncomeRatio(price, downPayment, income) >= 4.5) {
    return 0.01;
  } else {
    return 0;
  }
}

function calcAmortizationRate(price, downPayment) {
  let loanRatio = calcLoanRatio(price, downPayment);
  if (loanRatio >= 70) {
    return 0.02;
  } else if (loanRatio >= 50 && loanRatio <= 70) {
    return 0.01;
  } else {
    return 0;
  }
}

function calcAmortization(price, downPayment, income) {
  let extraAmortization = calcExtraAmortization(price, downPayment, income);
  let amortizationRate = calcAmortizationRate(price, downPayment);
  return price * (extraAmortization + amortizationRate);
}

function calcAmortizationPerMonth(price, downPayment, income) {
  return Math.round(calcAmortization(price, downPayment, income) / 12);
}

export default function Calc({ data }) {
  const [interest, setInterest] = useState(2.0);
  const [income, setIncome] = useState(400000);
  const [downPayment, setDownPayment] = useState(200000);
  const [persons, setPersons] = useState(1);
  const calculatedData = [];
  data.map((property) => {
    let id = property.id;
    let street = property.street;
    let rooms = property.rooms;
    let price = property.price;
    let fee = property.fee;
    let loan = calcLoan(price, downPayment);
    let loanRatio = calcLoanRatio(price, downPayment);
    let interestPerYear = calcInterest(price, downPayment, interest / 100);
    let interestPerMonth = calcInterestPerMonth(price, downPayment, interest / 100);
    let effectiveInterestRate =
      Math.round(calcEffectiveInterestRate(price, downPayment, interest / 100, persons) * 1000) /
      10;
    let interestDeductionPerMonth = calcInterestDeductionPerMonth(
      price,
      downPayment,
      interest / 100,
      persons
    );
    let totInterest = interestPerMonth - interestDeductionPerMonth;
    let incomeRatio = calcIncomeRatio(price, downPayment, income);
    let extraAmortization = calcExtraAmortization(price, downPayment, income);
    let amortizationRate = calcAmortizationRate(price, downPayment);
    let totAmortizationRate = extraAmortization + amortizationRate;
    let amortizationPerMonth = calcAmortizationPerMonth(price, downPayment, income);
    let totCost = totInterest + amortizationPerMonth + fee;
    calculatedData.push({
      id: id,
      street: street,
      rooms: rooms,
      price: price,
      fee: fee,
      loan: loan,
      loanRatio: loanRatio,
      interestPerYear: interestPerYear,
      interestPerMonth: interestPerMonth,
      effectiveInterestRate: effectiveInterestRate,
      interestDeductionPerMonth: interestDeductionPerMonth,
      totInterest: totInterest,
      incomeRatio: incomeRatio,
      amortizationRate: amortizationRate,
      extraAmortization: extraAmortization,
      totAmortizationRate: totAmortizationRate,
      amortizationPerMonth: amortizationPerMonth,
      totCost: totCost,
    });
  });
  console.log(calculatedData);
  return (
    <div className="container mx-auto py-4">
      <label
        htmlFor="default-range"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Ränta
      </label>
      <input
        type="range"
        className="custom-range"
        min="0"
        max="10"
        step="0.1"
        onChange={(event) => setInterest(event.target.value)}
        value={interest}
      />
      <input
        className="inputNumber"
        id="num1"
        min="0"
        max="100"
        step="0.1"
        type="number"
        value={interest}
        onChange={(event) => setInterest(event.target.value)}
      />
      <h4>Räntan är {interest}%</h4>
      <label
        htmlFor="default-range"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Kontantinsats
      </label>
      <input
        type="range"
        className="custom-range"
        min="0"
        max="1000000"
        step="1000"
        onChange={(event) => setDownPayment(event.target.value)}
        value={downPayment}
      />
      <input
        className="inputNumber"
        id="num1"
        min="0"
        max="10000000"
        type="number"
        step="1000"
        value={downPayment}
        onChange={(event) => setDownPayment(event.target.value)}
      />
      <label
        htmlFor="default-range"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Medlåntagare
      </label>
      <input
        type="range"
        className="custom-range"
        min="1"
        max="4"
        step="1"
        onChange={(event) => setPersons(event.target.value)}
        value={persons}
      />
      <input
        className="inputNumber"
        id="num1"
        min="1"
        max="4"
        type="number"
        step="1"
        value={persons}
        onChange={(event) => setPersons(event.target.value)}
      />
      <label
        htmlFor="default-range"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Inkomst
      </label>
      <input
        type="range"
        className="custom-range"
        min="0"
        max="2000000"
        step="10000"
        onChange={(event) => setIncome(event.target.value)}
        value={income}
      />
      <input
        className="inputNumber"
        id="num1"
        min="0"
        max="2000000"
        type="number"
        step="10000"
        value={income}
        onChange={(event) => setIncome(event.target.value)}
      />
      <div>
        Inkomsten påverkar amorteringsgraden. Lånar du 4.5 gånger din bruttoårsinkomst så behöver du
        amortera ytterligare 1 procentenhet.
      </div>
      <Table data={calculatedData} />
      <Interest data={calculatedData} />
      <Amortization data={calculatedData} />
    </div>
  );
}
