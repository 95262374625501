import React, { useState } from "react";

import ImageViewer from "react-simple-image-viewer";

export default function PropertyFloorplans({ floorPlansUrls }) {
  // Sources:
  // https://tailwindui.com/components/ecommerce/components/product-lists
  // https://tailwindui.com/components/application-ui/data-display/description-lists
  const [currentFloorplan, setCurrentFloorplan] = useState(0);
  const [isFloorplanViewerOpen, setIsFloorplanOpen] = useState(false);

  function openFloorplanViewer(index) {
    setCurrentFloorplan(index);
    setIsFloorplanOpen(true);
  }

  const closeFloorplanViewer = () => {
    setCurrentFloorplan(0);
    setIsFloorplanOpen(false);
  };
  return (
    <div>
      <h3
        className="font-medium leading-tight text-2xl mt-0 mb-2 mx-auto text-center"
        id="floorplans"
      >
        Planlösningar
      </h3>
      <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1">
          {floorPlansUrls.map((image, index) => (
            <div key={index} className="group relative">
              <div className="w-full bg-gray-200 overflow-hidden lg:hover:opacity-75 flex flex-column">
                <img
                  src={image}
                  onClick={() => openFloorplanViewer(index)}
                  // alt={product.imageAlt}
                  className="object-cover"
                  loading="lazy"
                />
              </div>
            </div>
          ))}
          {isFloorplanViewerOpen && (
            <ImageViewer
              src={floorPlansUrls}
              currentIndex={currentFloorplan}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={closeFloorplanViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
}
