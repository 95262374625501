import React from "react";
// from:
// https://tailwindui.com/components/application-ui/navigation/navbars

import Logo from "../Home/logo.png";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
export default function Navbar() {
  let navigation = [
    { name: "Startsida", to: "/" },
    { name: "Om", to: "/about" },
    { name: "Demo", to: "/demo" },
    { name: "Mina Bostäder", to: "/dashboard" },
    { name: "Försäljningsstatistik", to: "/sold" },
  ];
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 bg-gray-100 md:bg-white">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={Logo}
                      alt="Workflow"
                      loading="lazy"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={Logo}
                      alt="Workflow"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <div className="hidden lg:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) =>
                          isActive
                            ? "px-3 py-2 text-md font-medium hover:bg-indigo-100 border-b-4 border-b-indigo-500"
                            : "px-3 py-2 text-sm font-medium hover:bg-indigo-100"
                        }
                        // end
                        // If the end prop is used, it will ensure this component isn't matched as "active"
                        //  when its descendant paths are matched. For example, to render a link
                        //  that is only active at the website root and not any other URLs, you can use:
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            {({ close }) => (
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    onClick={async () => close()}
                    className={({ isActive }) =>
                      isActive
                        ? "block px-3 py-2 text-base font-medium hover:bg-indigo-100 border-b-4 border-b-indigo-500"
                        : "block px-3 py-2 text-base font-medium hover:bg-indigo-100"
                    }
                    // end
                    // If the end prop is used, it will ensure this component isn't matched as "active"
                    //  when its descendant paths are matched. For example, to render a link
                    //  that is only active at the website root and not any other URLs, you can use:
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
