import React from "react";
import useTitle from "../Util/title";

export default function SoldStatistics() {
  useTitle("Försäljningsstatistik - Grafer");
  return (
    <div className="container mx-auto max-w-7xl">
      <iframe
        className="mapBoxContainer"
        width="100%"
        src="https://observablehq.com/embed/@aleda145/bostadsbussen-analytics@651?cells=viewof+N%2Cviewof+county%2Cviewof+allCounties%2Cviewof+countyArea%2Cviewof+houseType%2Cviewof+allHouseTypes%2Cviewof+startLivingArea%2Cviewof+endLivingArea%2Cviewof+allAreas%2Cchart"
      ></iframe>
    </div>
  );
}
