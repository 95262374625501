import React from "react";
// import { useMutation } from "react-query";
import { useMutation, useQueryClient } from "react-query";
import { BsXSquareFill } from "react-icons/bs";
import ReactTooltip from "@huner2/react-tooltip";
// huner2 submitted a patch for react 18, which doesnt work on the offical tooltip package
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";

export default function RemoveProperty(data) {
  const client = useOutletContext();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (property) => {
      return client.records.delete("user_properties", property.property);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allProperties");
      },
      onError: (error) => {
        console.log(error);
        error.customError = error.response.data.message;
      },
    }
  );
  const addMutation = useMutation(
    (property) => {
      return client.records.create("user_properties", {
        user: client.authStore.model.id,
        property: property.property,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allProperties");
      },
      onError: (error) => {
        console.log(error);
        error.customError = error.response.data.message;
      },
    }
  );
  const notify = (url, street, imageUrl) =>
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex border-r border-gray-200">
          <button
            onClick={() => {
              addMutation.mutate(
                { property: data.propertyId },
                {
                  onSuccess: () => {
                    toast.remove(t.id);
                  },
                }
              );
            }}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Ångra
          </button>
        </div>
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-900">{street}</p>
              <p className="mt-1 text-sm text-gray-500">Har tagits bort</p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => {
              toast.remove(t.id);
            }}
            className="w-full border border-transparent rounded-none rounded-l-lg p-4 flex items-center justify-center text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Stäng
          </button>
        </div>
      </div>
    ));
  return (
    <div>
      <div className="">
        <button
          data-tip="Ta bort"
          type="submit"
          className="absolute top-0 right-0  pt-1 pr-1"
          onClick={() => {
            mutation.mutate(
              { property: data.id },
              { onSuccess: () => notify(data.hemnet_url, data.street, data.url) }
            );
          }}
        >
          <BsXSquareFill className="text-red-500 h-8 w-8 " aria-hidden="true" />
        </button>
      </div>
      <ReactTooltip place="top" type="error" effect="solid" />
    </div>
  );
}
