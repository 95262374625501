import React, { Fragment } from "react";
// import { getApiDomain } from "../App";
// import { useQuery } from "react-query";
import { Menu, Transition } from "@headlessui/react";

export default function NavLoggedIn({ client, logOutClicked }) {
  return (
    <div className="flex justify-end lg:static lg:inset-auto ">
      <div className="hidden sm:visible sm:inline-flex">
        <div className="block w-full px-3 mx-2 py-2">{client.authStore.model.email}</div>
        <button
          onClick={logOutClicked}
          className="block w-full px-3 mx-2 py-2 text-center font-medium text-indigo-600 hover:bg-indigo-100"
        >
          Logga ut
        </button>
      </div>
      <div className="visible sm:hidden">
        {/* Profile dropdown */}
        <Menu as="div" className="ml-3 relative">
          <div>
            <Menu.Button className="rounded-full bg-indigo-300 font-bold w-10 h-10 flex justify-center items-center">
              <span className="sr-only">Open user menu</span>
              {client.authStore.model.email[0].toUpperCase()}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right -top-2  -translate-y-full absolute right-0 mt-2 w-48 rounded-md shadow-lg  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                <button
                  onClick={logOutClicked}
                  className="block w-full px-3 py-2 text-center font-medium text-indigo-600 hover:bg-indigo-100"
                >
                  Logga ut
                </button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
