import React, { useState } from "react";
import PropTypes from "prop-types";
import { LockClosedIcon } from "@heroicons/react/solid";
export default function Auth({ client, setIsLoggedIn }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showLogin, setShowLogin] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const loginError = (
    <div>
      <div>Fel användarnamn eller lösenord.</div>
      <div>
        Vill du istället{" "}
        <span
          className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
          onClick={() => {
            setShowLogin(false);
            setShowError(false);
          }}
        >
          Skapa ett konto?
        </span>
      </div>
    </div>
  );
  const signUpError = (
    <div>
      <div>
        Kontot finns redan, vill du istället{" "}
        <span
          className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
          onClick={() => {
            setShowLogin(true);
            setShowError(false);
          }}
        >
          Logga in?
        </span>
      </div>
    </div>
  );

  async function loginFunction(username, password) {
    const userData = await client.Users.authViaEmail(username, password)
      .then(setShowLoading(true))
      .catch((err) => {
        console.log(JSON.stringify(err));
        setShowLoading(false);
        setShowError(true);
        setErrorMsg(loginError);
      });
    return userData;
  }
  const handleSubmit = async (e) => {
    setShowError(false);
    e.preventDefault();
    await loginFunction(username, password);
    setIsLoggedIn(client.AuthStore.isValid);
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    await client.Users.create({
      email: username,
      password: password,
      passwordConfirm: password,
    })
      .then(setShowLoading(true))
      .catch((err) => {
        console.log(JSON.stringify(err));
        setShowLoading(false);
        setShowError(true);
        setErrorMsg(signUpError);
        throw err;
      });

    await client.Users.authViaEmail(username, password);
    setIsLoggedIn(client.AuthStore.isValid);
  };
  return (
    <div className="login-wrapper">
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        {showLogin ? (
          <div className="max-w-md w-full space-y-8">
            <div>
              <div className="flex justify-center py-6">
                <span className="animate-bounce text-6xl">🚌</span>
              </div>
              <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                Logga in till Mina Bostäder
              </h2>
              <div className="mt-2 text-center text-sm text-gray-600">
                Eller{" "}
                <div
                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  onClick={() => {
                    setShowLogin(false);
                    setShowError(false);
                  }}
                >
                  Skapa ett konto
                </div>
              </div>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Lösenord"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Kom ihåg mig
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Glömt lösenord?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Logga in
                </button>
              </div>
            </form>
            {showLoading && <div>Loggar in...</div>}
            {showError && errorMsg}
          </div>
        ) : (
          <div className="max-w-md w-full space-y-8">
            <div>
              <div className="flex justify-center py-6">
                <span className="animate-bounce text-6xl">🚌</span>
              </div>

              <h2 className="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900">
                Skapa ett konto
              </h2>
              <div className="mt-2 text-center text-sm text-gray-600">
                Eller{" "}
                <div
                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  onClick={() => {
                    setShowLogin(true);
                    setShowError(false);
                  }}
                >
                  Logga in
                </div>
              </div>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSignUp}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    minLength="8"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Lösenord"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  Skapa ett konto
                </button>
              </div>
            </form>
            {showLoading && <div>Skapar ett konto och loggar in...</div>}
            {showError && errorMsg}
          </div>
        )}
      </div>
    </div>
  );
}

Auth.propTypes = {
  client: PropTypes.any.isRequired,
};
