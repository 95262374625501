import React from "react";

export default function SearchStreet({ searchStreet, setSearchStreet }) {
  return (
    <div className="container mx-auto max-w-3xl px-1">
      <div className="flex items-center justify-center">
        <div>
          <input
            type="text"
            value={searchStreet}
            onChange={(event) => setSearchStreet(event.target.value)}
            placeholder="Filtrera på gatunamn"
          ></input>
        </div>
      </div>
    </div>
  );
}
