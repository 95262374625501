import React from "react";
import { Link } from "react-router-dom";
export default function Breadcrumb() {
  return (
    <div className="container mx-auto pl-4 mb-3">
      <Link to="/dashboard" className="text-indigo-500 hover:text-indigo-700">
        &#60; Tillbaka till Mina Bostäder
      </Link>
    </div>
  );
}
