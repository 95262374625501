import React from "react";
import { BsHouseDoor } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BsSquare, BsExclamationSquare } from "react-icons/bs";
import RemoveProperty from "./RemoveProperty";

export default function Card({ property, relationId }) {
  function propertyLink() {
    let prop_link = "/dashboard/property/" + property.id;
    return prop_link;
  }
  let displayClosingPrice = false;
  let displayWasRemoved = false;
  if (property.closing_price != -1 && property.removed) {
    displayClosingPrice = true;
  }
  if (property.closing_price == -1 && property.removed) {
    displayWasRemoved = true;
  }
  let displayStatus = displayWasRemoved || displayClosingPrice;
  return (
    <div className="border relative">
      <Link to={propertyLink()}>
        <div className="w-full bg-gray-200 overflow-hidden lg:hover:opacity-75 flex flex-column">
          <img
            className="w-full h-60 object-cover"
            src={
              "@expand" in property
                ? property["@expand"].first_image.url
                : "https://placekitten.com/g/600/400"
            }
            alt=""
            loading="lazy"
          />
        </div>
        <div className="p-5">
          <div className="grid grid-cols-12 text-sm text-slate-600 pb-2">
            {property.area ? (
              <div className="col-span-6 truncate">{property.area}</div>
            ) : (
              <div className="col-span-6 truncate ">Okänt område</div>
            )}
            <div className="col-span-3 flex justify-end">
              <BsSquare className="h-5 w-5" />
              <span className="pl-2">{property.sqm_area} m²</span>
            </div>
            <div className="col-span-3 flex justify-end">
              <BsHouseDoor className="h-5 w-5" />
              <span className="pl-2">{property.rooms}</span>
            </div>
          </div>
          <h5 className="mb-2 text-2xl font-bold overflow-hidden truncate w-full">
            {property.street}
          </h5>
          <div className="text-md mb-2">
            {displayClosingPrice ? (
              <div className="grid grid-cols-1">
                <div className="grid grid-cols-5 mb-3">
                  <span className="col-span-1 mr-3 text-center border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100">
                    Utrop
                  </span>
                  <span className="col-span-4">
                    {property.price.toLocaleString().replace(/,/g, " ")} kr
                  </span>
                </div>
                <div className="grid grid-cols-5">
                  <span className="col-span-1  mr-3 text-center border border-transparent text-base font-medium rounded-md text-yellow-700 bg-yellow-100">
                    Slut
                  </span>
                  <span className="col-span-4">
                    {property.closing_price.toLocaleString().replace(/,/g, " ")} kr
                  </span>
                </div>
              </div>
            ) : (
              <div>{property.price.toLocaleString().replace(/,/g, " ")} kr</div>
            )}
          </div>
        </div>
        {displayStatus && (
          <div className="absolute top-0 left-0  py-1 px-1 bg-gray-200/75 ">
            <div className="flex justify-end">
              <BsExclamationSquare className="h-6 w-6 text-indigo-500" />
              <div className="text-indigo-500 font-bold  ">
                {displayClosingPrice && <span className="pl-2">Såld</span>}
                {displayWasRemoved && <span className="pl-2">Borttagen</span>}
              </div>
            </div>
          </div>
        )}
      </Link>
      <RemoveProperty
        id={relationId}
        propertyId={property.id}
        street={property.street}
        url={
          "@expand" in property
            ? property["@expand"].first_image.url
            : "https://placekitten.com/g/600/400"
        }
        hemnet_url={property.hemnet_url}
      />
    </div>
  );
}
