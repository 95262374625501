import React from "react";
import useTitle from "../Util/title";

export default function SoldStatistics() {
  useTitle("Försäljningsstatistik - Data");
  return (
    <div className="container mx-auto max-w-7xl">
      <div className="p-10">
        <h1 className="text-2xl">Om datan</h1>
        <div className="mt-2">
          Data kommer från{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://www.hemnet.se/salda/bostader?"
          >
            hemnet.se
          </a>{" "}
          som jag extraherat med web scraping. För att undvika onödig belastning paralelliserade jag
          inte applikationen. Den skickade en request i taget, så det tog ungefär en vecka i realtid
          att hämta all data.
        </div>
        <div>
          Datan är även väldigt <span className="italic">rå </span> (alltså precis som den hämtades
          från hemnet), så det förekommer en del <span className="italic">skräpdata</span> . Verkar
          som mäklare ibland slinter med fingrarna när de ska mata in data! 🤓
        </div>
        <h1 className="mt-3 text-2xl">Format</h1>
        <div className="mt-2">
          Data finns att ladda ner som{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://en.wikipedia.org/wiki/Comma-separated_values"
          >
            CSV
          </a>{" "}
          eller som{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://en.wikipedia.org/wiki/JSON"
          >
            JSON
          </a>
          . Den är komprimerad med{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://en.wikipedia.org/wiki/Gzip"
          >
            gzip
          </a>
          , så den behöver extraheras efter nerladdning. På Linux/Mac extraheras det lätt med:
        </div>
        <code>gzip -d</code>
        <div className="mt-2">
          På annat OS så fungerar exempelvis{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://en.wikipedia.org/wiki/7-Zip"
          >
            7-zip
          </a>
          .
        </div>
        <div className="mt-2">
          Det är en del data, så ett vanligt verktyg som Excel kanske inte klarar av det. Ett
          förslag är att kolla på{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800"
            href="https://en.wikipedia.org/wiki/Pandas_(software)"
          >
            pandas
          </a>{" "}
          som klarar det galant!
        </div>
        <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-5">
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <a href="https://data.bostadsbussen.se/properties.json.gz">
                <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Ladda ner som JSON
                </span>
              </a>
            </div>
          </div>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <a href="https://data.bostadsbussen.se/properties.csv.gz">
                <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Ladda ner som CSV
                </span>
              </a>
            </div>
          </div>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <a href="https://data.bostadsbussen.se/properties.parquet">
                <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Ladda ner som Parquet
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
