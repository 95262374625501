import React from "react";
import Hero from "./Hero";
import useTitle from "../Util/title";
export default function Home() {
  useTitle("Bostadsbussen");
  return (
    <div className="">
      <Hero></Hero>
    </div>
  );
}
