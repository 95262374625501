import React, { useState } from "react";

import useTitle from "../Util/title";
import NavLoggedIn from "./NavLoggedIn";
import { Outlet } from "react-router-dom";
import { useNavigate, NavLink } from "react-router-dom";
import PocketBase from "pocketbase";
import Auth from "../Auth/pocketbase";
import { getApiDomain } from "../App";

export default function Dashboard() {
  useTitle("Mina Bostäder");
  const navigate = useNavigate();

  const client = new PocketBase(getApiDomain());
  const [isLoggedIn, setIsLoggedIn] = useState(client.authStore.isValid);
  if (isLoggedIn) {
    console.log("refreshing");
    client.users.refresh().catch((error) => {
      console.log(error);
      client.authStore.clear();
      setIsLoggedIn(false);
    });
  }
  if (!isLoggedIn) {
    return <Auth client={client} setIsLoggedIn={setIsLoggedIn} />;
  }

  async function logOutClicked() {
    client.authStore.clear();
    setIsLoggedIn(false);
    navigate("/");
  }
  let loggedInNavigation = [];
  loggedInNavigation = [
    { name: "Bostäder", to: "/dashboard" },
    { name: "Karta", to: "/dashboard/map" },
    { name: "Kalkyl", to: "/dashboard/calculate" },
  ];
  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden"></div>
          <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
            <div className="lg:block sm:ml-6">
              <div className="flex space-x-4">
                {loggedInNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) =>
                      isActive
                        ? "px-3 py-2 text-md font-medium hover:bg-indigo-100 border-b-4 border-b-indigo-500"
                        : "px-3 py-2 text-sm font-medium hover:bg-indigo-100"
                    }
                    end
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <NavLoggedIn client={client} logOutClicked={logOutClicked} />
        </div>
      </div>

      <Outlet context={client} />
    </div>
  );
}
