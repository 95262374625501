import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import useTitle from "../Util/title";
import { Switch } from "@headlessui/react";

// Leaflet lol what:
// https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
import L from "leaflet";
import { Link } from "react-router-dom";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import LoadingAnimation from "../LoadingAnimation";

import { useQuery } from "react-query";
import { useOutletContext } from "react-router-dom";

import Markers from "./Markers";
export default function Map() {
  useTitle("Karta");
  const [hideRemoved, setHideRemoved] = React.useState(false);
  const client = useOutletContext();

  const { isLoading, error, data } = useQuery(["mapProperties"], () =>
    client.Records.getFullList("user_properties", 200 /* batch size */, {
      sort: "-created",
      expand: "property.first_image",
      filter: "",
    })
  );

  if (isLoading) return <LoadingAnimation />;

  if (error) return "An error has occurred: " + error.message;

  const nodata = (
    <div className="container mx-auto flex">
      <div className="flex mx-auto">
        <div className="max-w-xl px-1 py-3 ">
          <p className="">
            För att se en karta på dina bostäder måste du först lägga till bostäder!
          </p>
          <div className="mt-5 sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <Link
                to="/dashboard"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                Gå till Mina Bostäder
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (data.length == 0) {
    return nodata;
  }
  let allProperties = { properties: [] };
  let notRemovedProperties = { properties: [] };
  let maxLatAll = -999;
  let maxLongAll = -999;
  let minLatAll = 999;
  let minLongAll = 999;
  if (data != null) {
    data.forEach((object) => {
      let property = object["@expand"]["property"];
      if (property.removed != 1) {
        notRemovedProperties.properties.push(property);
      }
      if (property.latitude >= maxLatAll) {
        maxLatAll = property.latitude;
      }
      if (property.longitude >= maxLongAll) {
        maxLongAll = property.longitude;
      }
      if (property.latitude <= minLatAll) {
        minLatAll = property.latitude;
      }
      if (property.longitude <= minLongAll) {
        minLongAll = property.longitude;
      }
      allProperties.properties.push(property);
    });
  }
  return (
    <div className="container mx-auto">
      <div className="container mx-auto max-w-3xl px-1 text-center mb-2">
        <div className="font-bold mb-1">Göm sålda och borttagna</div>
        <Switch
          checked={hideRemoved}
          onChange={setHideRemoved}
          className={`${
            hideRemoved ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              hideRemoved ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white`}
          />
        </Switch>
      </div>
      <div className="relative">
        <MapContainer
          bounds={[
            [maxLatAll, maxLongAll],
            [minLatAll, minLongAll],
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {hideRemoved ? <Markers data={notRemovedProperties} /> : <Markers data={allProperties} />}
        </MapContainer>
      </div>
    </div>
  );
}
