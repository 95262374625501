/// app.js
import React, { useState, useEffect } from "react";
import Layers from "./Layers";
import ControlPanel from "./ControlPanel";
import { getPropertyUrl } from "../App";
import LoadingAnimation from "../LoadingAnimation";
import pako from "pako";

export default function MapView() {
  const [elevation, setElevation] = useState(true);
  const [dataSize, setDataSize] = useState();
  const [radius, setRadius] = useState(10000);
  const [percentile, setPercentile] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [maxColorValue, setMaxColorValue] = useState(0);
  const [minColorValue, setMinColorValue] = useState(0);
  const [jsonDataSet, setJsonData] = useState(null);
  const [dataFilters, setDataFilters] = useState(["apartment", "house", "townhouse"]);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  const [loadingAnimationText, setLoadingAnimationText] = useState("Laddar...");
  const [nextLoadDataOnLoad, setNextLoadDataOnLoad] = useState(
    JSON.parse(localStorage.getItem("loadDataOnLoad"))
  );
  const [loadDataOnLoad, setLoadDataOnLoad] = useState(
    JSON.parse(localStorage.getItem("loadDataOnLoad"))
  );
  const [colorField, setColorField] = useState("sqm_price");
  const [numberOfObjects, setNumberOfObjects] = useState("-");
  const colorRange = [
    [1, 152, 189],
    [73, 227, 206],
    [216, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [209, 55, 78],
  ];
  function loadData() {
    if (!jsonDataSet) {
      const url = getPropertyUrl();
      setLoadingAnimationText("Hämtar data...");
      setShowLoadingAnimation(true);
      fetch(url)
        .then((response) => {
          return response.arrayBuffer();
        })
        .then((responseBuffer) => {
          if (nextLoadDataOnLoad) {
            setLoadDataOnLoad(nextLoadDataOnLoad);
          }
          setLoadingAnimationText("Extraherar data...");
          let inflated = pako.inflate(responseBuffer);
          const json = JSON.parse(new TextDecoder().decode(inflated));
          setShowLoadingAnimation(false);
          setJsonData(json);
        })
        .catch((error) => {
          setShowLoadingAnimation(false);
          console.error(error);
        });
    }
  }
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "-";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    localStorage.setItem("loadDataOnLoad", JSON.stringify(loadDataOnLoad));
  }, [loadDataOnLoad]);

  useEffect(() => {
    if (loadDataOnLoad) {
      loadData();
    } else {
      const url = getPropertyUrl();
      fetch(url, { method: "HEAD" })
        .then((response) => {
          setDataSize(Number(response.headers.get("content-length")));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [null]);
  return (
    <div className="container mx-auto relative">
      {jsonDataSet ? null : (
        <div className="absolute top-1/2 md:left-1/2 transform md:-translate-x-1/2 -translate-y-1/2 z-20 mt-5 sm:mt-8 flex justify-center w-full md:w-auto bg-white p-3 border-2 border-indigo-500">
          <div className="">
            {loadDataOnLoad ? (
              ""
            ) : (
              <div>
                <div className="max-w-sm mb-1">
                  För att se visualiseringen över säljstatistik behöver {formatBytes(dataSize, 0)}{" "}
                  data laddas ner. Det kan ta lite tid om du har en dålig uppkoppling eller
                  begränsat med mobildata. Klicka på knappen för att ladda datan!
                </div>
                <div className="mb-3">
                  <span className="mr-2">Fråga inte igen: </span>
                  <input
                    type="checkbox"
                    checked={nextLoadDataOnLoad}
                    onChange={() => setNextLoadDataOnLoad(!nextLoadDataOnLoad)}
                  />
                </div>
              </div>
            )}
            {showLoadingAnimation ? (
              <LoadingAnimation loadingText={loadingAnimationText} />
            ) : (
              <div>
                {loadDataOnLoad || (
                  <button
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    onClick={() => loadData()}
                  >
                    Ladda data
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <ControlPanel
        radius={radius}
        setRadius={setRadius}
        elevation={elevation}
        setElevation={setElevation}
        percentile={percentile}
        setPercentile={setPercentile}
        colorRange={colorRange}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        minColorValue={minColorValue}
        maxColorValue={maxColorValue}
        loadDataOnLoad={loadDataOnLoad}
        setLoadDataOnLoad={setLoadDataOnLoad}
        setColorField={setColorField}
        colorField={colorField}
        setDataFilters={setDataFilters}
        numberOfObjects={numberOfObjects}
      />
      <Layers
        jsonblob={jsonDataSet}
        elevation={elevation}
        radius={radius}
        percentile={percentile}
        colorRange={colorRange}
        setMaxColorValue={setMaxColorValue}
        setMinColorValue={setMinColorValue}
        colorField={colorField}
        startDate={startDate}
        endDate={endDate}
        dataFilters={dataFilters}
        setNumberOfObjects={setNumberOfObjects}
      />
    </div>
  );
}
