import React from "react";
import useTitle from "../Util/title";
import Calc from "./Calc.js";

import { Link } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";
import { Switch } from "@headlessui/react";
// import { getApiDomain } from "../App";
import { useQuery } from "react-query";
import { useOutletContext } from "react-router-dom";

export default function Calculate() {
  useTitle("Kalkyl");
  const [hideRemoved, setHideRemoved] = React.useState(false);
  const client = useOutletContext();
  const { isLoading, error, data } = useQuery(["calcProperties"], () =>
    client.Records.getFullList("user_properties", 200 /* batch size */, {
      sort: "-created",
      expand: "property",
      filter: "",
    })
  );

  if (isLoading) return <LoadingAnimation />;

  if (error) return "An error has occurred: " + error.message;
  // console.log(client.authStore);
  const nodata = (
    <div className="container mx-auto flex">
      <div className="flex mx-auto">
        <div className="max-w-xl px-1 py-3 ">
          <p className="">
            För att se en kalkyl på dina bostäder måste du först lägga till bostäder!
          </p>
          <div className="mt-5 sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <Link
                to="/dashboard"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                Gå till Mina Bostäder
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (data.length == 0) return nodata;
  // console.log(columns);
  let allProperties = [];
  let notRemovedProperties = [];
  data.forEach((object) => {
    let property = object["@expand"]["property"];

    if (property.removed != 1) {
      notRemovedProperties.push(property);
    }
    allProperties.push(property);
  });
  return (
    <div className="container mx-auto">
      <div className="container mx-auto max-w-3xl px-1 text-center mb-2">
        <div className="font-bold mb-1">Göm sålda och borttagna</div>
        <Switch
          checked={hideRemoved}
          onChange={setHideRemoved}
          className={`${
            hideRemoved ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              hideRemoved ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white`}
          />
        </Switch>
      </div>
      {hideRemoved ? <Calc data={notRemovedProperties} /> : <Calc data={allProperties} />}
    </div>
  );
}
