/// app.js
import React from "react";
// import { useState, useEffect } from "react";
import { HexagonLayer } from "@deck.gl/aggregation-layers";

// data load
// import { JSONLoader } from "@loaders.gl/json";
// import { load } from "@loaders.gl/core";

import "mapbox-gl/dist/mapbox-gl.css";

import SoldMap from "./Map";

export default function Layers({
  radius,
  elevation,
  percentile,
  colorRange,
  setMinColorValue,
  setMaxColorValue,
  jsonblob,
  colorField,
  startDate,
  endDate,
  dataFilters,
  setNumberOfObjects,
}) {
  let filteredData;
  const colorFunc = colorField == "count" ? () => 1 : (point) => point[colorField];
  const colorAggFunc = colorField == "count" ? "SUM" : "MEAN";
  if (jsonblob != null) {
    // TODO this can probably be done nicer with a useEffect?
    filteredData = jsonblob.filter((a) => dataFilters.includes(a.house_type));
    filteredData = filteredData.filter((a) => {
      var soldDate = new Date(a.datetime);
      return soldDate >= startDate && soldDate <= endDate;
    });
    setNumberOfObjects(filteredData.length);
  }
  const coverage = 1;
  const layers = [
    new HexagonLayer({
      id: "heatmap",
      colorRange,
      coverage: coverage,
      opacity: 0.5,
      data: filteredData,
      radius: radius,
      elevationRange: [0, elevation ? radius : 0],
      elevationScale: filteredData && filteredData.length ? 50 : 0,
      getColorWeight: colorFunc,
      colorAggregation: colorAggFunc,
      getElevationWeight: () => 1,
      elevationAggregation: "SUM",
      extruded: true,
      getPosition: (d) => [d.longitude, d.latitude],
      pickable: true,
      upperPercentile: percentile ? 100 : 99.9,
      lowerPercentile: 100 - (percentile ? 100 : 99.9),
      onSetColorDomain: (event) => {
        setMinColorValue(event[0]);
        setMaxColorValue(event[1]);
      },
    }),
  ];

  return <SoldMap layers={layers} />;
}
