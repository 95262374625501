import React from "react";
// from:
// https://tailwindui.com/components/application-ui/navigation/navbars

import NavBar from "./Navbar";

export default function Navigation() {
  return (
    <div>
      <NavBar />
    </div>
  );
}
