import React from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import LoadingAnimation from "../LoadingAnimation";
import { useOutletContext } from "react-router-dom";

import PropertyFloorplans from "./Floorplans";
import Breadcrumb from "./Breadcrumb";
import PropertyImages from "./Images";
import PropertyDescription from "./Description";

export default function Property() {
  // Sources:
  // https://tailwindui.com/components/ecommerce/components/product-lists
  // https://tailwindui.com/components/application-ui/data-display/description-lists
  const client = useOutletContext();

  let params = useParams();
  const { isLoading, error, data } = useQuery("getProperty" + params.propertyId, () =>
    client.Records.getOne("property", params.propertyId, {
      expand: "images",
    })
  );
  if (isLoading) return <LoadingAnimation />;
  if (error) return "An error has occurred: " + error.message;
  let imageUrls = [];
  let floorPlansUrls = [];
  if (data != null && "@expand" in data) {
    data["@expand"]["images"].forEach((image) => {
      if (image["is_floor_plan"] == 0) {
        imageUrls.push(image["url"]);
      } else {
        floorPlansUrls.push(image["url"]);
      }
    });
  }
  return (
    <div>
      <Breadcrumb />
      <PropertyDescription data={data} />
      <PropertyFloorplans floorPlansUrls={floorPlansUrls} />
      <PropertyImages imageUrls={imageUrls} />
    </div>
  );
}
