import React from "react";

export default function LoadingAnimation({ loadingText = "Laddar..." }) {
  return (
    <div className="container text-center mx-auto">
      <p>{loadingText}</p>
      <br></br>
      <div className="animate-bounce">
        <span className="animate-bounce text-6xl">🚌</span>
      </div>
    </div>
  );
}
