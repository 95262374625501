import React, { useState } from "react";
import useTitle from "../Util/title";
import { sha256 } from "js-sha256";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import CodeEditor from "@uiw/react-textarea-code-editor";
import LoadingAnimation from "../LoadingAnimation";
import { getSeafowlURL } from "../App";
import { Switch } from "@headlessui/react";

async function querySeafowl(sql, localCache, cloudflare) {
  let startTimeSeaFowl = performance.now();
  const host = getSeafowlURL();
  let query = sql.trim();
  let queryHash = sha256(query);
  const fetchOptions = {
    headers: {
      "X-Seafowl-Query": encodeURIComponent(query),
    },
    mode: "cors",
    method: "GET",
    ...(!localCache && { cache: "no-store" }),
  };
  // Append a random extension (.csv) that Seafowl discards but that makes
  // Cloudflare treat the query response as a static cacheable asset.
  const extension = cloudflare ? ".csv" : "";
  const url = host + "/q/" + queryHash + extension;
  let response = await fetch(url, fetchOptions);
  if (!response.ok) throw new Error(await response.text());
  let response_t = await response.text();
  let timeSeaFowl = performance.now() - startTimeSeaFowl;
  let timeJavaScript = performance.now();
  let rows = response_t.trim().split("\n").map(JSON.parse);
  let keys = Object.keys(rows[0]);
  let columns = keys.map((x) => ({
    key: x,
    name: x,
    resizable: true,
    width: 125,
  }));

  return response_t
    ? {
        rows: rows,
        columns: columns,
        timeSeafowl: timeSeaFowl,
        timeJavaScript: performance.now() - timeJavaScript,
      }
    : {};
}
export default function SoldStatistics() {
  const [query, setQuery] = useState("SELECT\n    *\nFROM\n    properties\nLIMIT\n    100");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [queryError, setQueryError] = useState("");
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [useLocalCache, setUseLocalCache] = useState(true);
  const [useCloudflare, setUseCloudflare] = useState(true);
  const [querySeafowlTime, setQuerySeafowlTime] = useState(0);
  const [queryJSTime, setQueryJSTime] = useState(0);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  useTitle("Försäljningsstatistik - Query");
  return (
    <div className="container mx-auto max-w-7xl">
      <CodeEditor
        value={query}
        language="sql"
        placeholder="Please enter some SQL."
        onChange={(event) => setQuery(event.target.value)}
        // TODO add shift+enter on KeyPressDown, but it's broken: https://github.com/uiwjs/react-textarea-code-editor/issues/144
        onKeyDown={async (event) => {
          if (event.key == " " && event.ctrlKey == true) {
            setShowLoadingAnimation(true);
            let result = await querySeafowl(query, useLocalCache, useCloudflare).catch((err) => {
              setQueryError(err.message);
            });
            if (result != undefined) {
              setRows(result.rows);
              setColumns(result.columns);
              setNumberOfRows(result.rows.length);
              setQuerySeafowlTime(result.timeSeafowl);
              setQueryJSTime(result.timeJavaScript);
              setQueryError("");
            }
            setShowLoadingAnimation(false);
          }
        }}
        padding={15}
        style={{
          fontSize: 12,
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
      <div className="mt-2 mb-3">
        <button
          onClick={async () => {
            setShowLoadingAnimation(true);
            let result = await querySeafowl(query, useLocalCache, useCloudflare).catch((err) => {
              setQueryError(err.message);
            });
            if (result != undefined) {
              setRows(result.rows);
              setColumns(result.columns);
              setNumberOfRows(result.rows.length);
              setQuerySeafowlTime(result.timeSeafowl);
              setQueryJSTime(result.timeJavaScript);
              setQueryError("");
            }
            setShowLoadingAnimation(false);
          }}
          className="px-8 py-3 border border-transparent
          text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4
          md:text-lg md:px-10"
        >
          {" "}
          Execute Query
          <div className="text-sm">(ctrl+space)</div>
        </button>
        {showLoadingAnimation && <LoadingAnimation loadingText="Running Query" />}
      </div>
      {queryError && (
        <div
          className="p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          {queryError}
        </div>
      )}
      <div className="font-bold mb-1">Use Local Cache</div>

      <Switch
        checked={useLocalCache}
        onChange={setUseLocalCache}
        className={`${
          useLocalCache ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            useLocalCache ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white`}
        />
      </Switch>
      <div className="font-bold mb-1">Use Cloudflare</div>

      <Switch
        checked={useCloudflare}
        onChange={setUseCloudflare}
        className={`${
          useCloudflare ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            useCloudflare ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white`}
        />
      </Switch>
      <div>Number of rows: {numberOfRows}</div>
      <div>Query time (Seafowl): {querySeafowlTime}ms</div>
      <div>Query time (Javascript): {queryJSTime}ms</div>
      <div>Query time (Total): {queryJSTime + querySeafowlTime}ms</div>
      <DataGrid columns={columns} rows={rows} />
    </div>
  );
}
