import React from "react";
import { Link } from "react-router-dom";

import "./index.css";

import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";
function propertyLink(id) {
  let prop_link = "/dashboard/property/" + id;
  return prop_link;
}
function formatToCrowns(number) {
  if (number != null) {
    return number.toLocaleString().replace(/,/g, " ") + " kr";
  } else return "";
}

function redIfLoanRatioIsBad(ratio) {
  if (ratio > 85) {
    return "text-red-400";
  } else {
    return "";
  }
}
export default function Table({ data }) {
  const defaultColumns = [
    {
      header: "Bostäder",
      columns: [
        {
          accessorKey: "street",
          cell: (info) => <Link to={propertyLink(info.row.original.id)}>{info.getValue()}</Link>,
          header: () => <span>Adress</span>,
        },
        { accessorKey: "rooms", cell: (info) => info.getValue(), header: () => <span>Rum</span> },
        {
          accessorKey: "price",
          header: () => <span>Utropspris</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "loan",
          header: () => <span>Lån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "loanRatio",
          header: () => <span>Lånegrad</span>,
          cell: (info) => (
            <span className={redIfLoanRatioIsBad(info.getValue())}>{info.getValue()}% </span>
          ),
        },
        {
          accessorKey: "totInterest",
          header: () => <span>Räntekostnad/mån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "amortizationPerMonth",
          header: () => <span>Amortering/mån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "fee",
          header: () => <span>Avgift/mån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "totCost",
          header: () => <span>Tot. Utgifter/mån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
      ],
    },
  ];
  // const [data, setData] = React.useState(() => [...defaultData]);
  // const [data] = React.useState(() => [...defaultData]);
  const [columns] = [defaultColumns]; // TODO here i am hacking! Usestate correctly?
  const [sorting, setSorting] = React.useState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="p-2">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
