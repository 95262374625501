import React from "react";
import bussen from "./bussen.png";
import SoldMap from "../SoldStatistics/sold_map.png";

import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="container mx-auto">
      <div className="px-4 mt-12">
        <main className="sm:mt-12 sm:px-6">
          <div className="sm:text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Jämförelseverktyg för ditt</span>{" "}
              <span className="block text-indigo-600 xl:inline">bostadsköp</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">
              Att köpa en bostad är både svårt och dyrt 💸! För att det ska bli helt rätt så behöver
              man sammanställa en väldigt massa information.
            </p>
            <p className="mt-3 text-base text-black-500 sm:mt-5 sm:text-lg  md:mt-5 md:text-2xl lg:mx-0">
              Mäklarfirmorna gör inte detta lätt för oss.
            </p>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg  md:mt-5 md:text-xl lg:mx-0">
              På den här sidan kan du ta tillbaka informationskontrollen från mäklarna!
            </p>
            <br></br>
          </div>
          <div className="p-2 md:p-5 lg:p-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-center">
            <div className="rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={bussen} alt="Bostadsbussen" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Spara och räkna</div>
                <ul className="list-none">
                  <li>✔️ Spara data från hemnet</li>
                  <li>✔️ Räkna på månadskostnaden</li>
                  <li>✔️ Bilderna är kvar efter den har sålts</li>
                  <li>✔️ Jämför objekt lätt i en tabell</li>
                </ul>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                  <div className="rounded-md shadow">
                    <Link
                      to="/dashboard"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Kom igång!
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/demo"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                    >
                      Live demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={SoldMap} alt="Bostadsbussen" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Försäljningsstatistik</div>
                <ul className="list-none">
                  <li>✔️ Karta över alla sålda objekt</li>
                  <li>✔️ Grafer på försäljningspris över tid</li>
                  <li>✔️ Ladda ner datan och experimentera själv</li>
                </ul>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                  <div className="rounded-md shadow">
                    <Link
                      to="/sold"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Gå till statistiken
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded overflow-hidden shadow-lg grayscale">
              <img className="w-full h-48 object-cover" src={SoldMap} alt="Bostadsbussen" />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Visningshjälp (Kommer snart)</div>
                <ul className="list-none">
                  <li>✔️ En interaktiv guide till bostadsvisningen</li>
                  <li>✔️ Laddad med frågor så du inte missar något</li>
                  <li>✔️ Spara resultatet</li>
                </ul>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                  <div className="rounded-md shadow">
                    {/* <Link
                      to="/sold"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                    Visning
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
