import React, { useState } from "react";
import Select from "react-select";
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { Switch } from "@headlessui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
function formatToColorRange(number, colorField) {
  if (number != null) {
    let formatNumber = Math.floor(number).toLocaleString().replace(/,/g, " ");
    if (colorField == "price") {
      return formatNumber + "kr";
    } else if (colorField == "sqm_price") {
      return formatNumber + "kr/m²";
    } else if (colorField == "count") {
      return formatNumber;
    } else if (colorField == "living_area") {
      return formatNumber + "m²";
    }
  } else return "";
}

export default function ControlPanel({
  elevation,
  setElevation,
  radius,
  setRadius,
  percentile,
  setPercentile,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  colorRange,
  minColorValue,
  maxColorValue,
  loadDataOnLoad,
  setLoadDataOnLoad,
  setColorField,
  colorField,
  setDataFilters,
  numberOfObjects,
}) {
  const [loadDataChanged, setLoadDataChanged] = useState(false);
  const [showControlPanel, setShowControlPanel] = useState(true);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);
  const selectOptions = [
    { value: "apartment", label: "Bostadsrätt" },
    { value: "house", label: "Villa" },
    { value: "townhouse", label: "Radhus" },
  ];
  const colorElevationOptions = [
    { value: "count", label: "Antal" },
    { value: "sqm_price", label: "Kvadratmeterpris" },
    { value: "price", label: "Pris" },
    { value: "living_area", label: "Kvadratmeter" },
  ];
  const activeButton =
    "px-3 py-2 text-md font-medium hover:bg-indigo-100 border-b-4 border-b-indigo-500";
  const notActiveButton = "px-3 py-2 text-sm font-medium hover:bg-indigo-100";
  return (
    <div
      id="control-panel"
      className="absolute z-10 md:top-0 md:right-0 bg-white md:mt-5 md:mr-10 md:p-6 md:w-96 p-4 w-full"
    >
      <div>
        <span
          className="flex cursor-pointer"
          onClick={() => setShowControlPanel((showControlPanel) => !showControlPanel)}
        >
          <span className="font-medium leading-tight text-4xl">Säljstatistik</span>
          <span className="absolute top-6 right-0 ">
            {showControlPanel ? (
              <span className="flex justify-end">
                <span className="px-2 font-bold">Göm</span>
                <button>
                  <BsEyeSlash className="text-indigo-500 h-8 w-8 mr-2" />
                </button>
              </span>
            ) : (
              <span className="flex justify-end">
                <span className="px-2 font-bold">Visa</span>
                <button>
                  <BsEye className="text-indigo-500 h-8 w-8 mr-2" />
                </button>
              </span>
            )}
          </span>
        </span>
      </div>
      <div className="grid grid-cols-3">
        <div>{formatToColorRange(minColorValue, colorField)}</div>
        <div></div>
        <div className="text-right">{formatToColorRange(maxColorValue, colorField)}</div>
      </div>
      <div className="grid grid-cols-6">
        {colorRange.map((color, index) => (
          <div className="h-4" key={index} style={{ background: `rgb(${color})` }}></div>
        ))}
      </div>
      {showControlPanel ? (
        <div>
          <hr className="mt-2" />
          <div className="inputs">
            <div className="mt-2 text-center font-bold">Radie</div>
            <div className="grid grid-cols-5 mt-2">
              <button
                className={radius == 10 ? activeButton : notActiveButton}
                onClick={() => setRadius(10)}
              >
                10m
              </button>
              <button
                className={radius == 50 ? activeButton : notActiveButton}
                onClick={() => setRadius(50)}
              >
                50m
              </button>
              <button
                className={radius == 100 ? activeButton : notActiveButton}
                onClick={() => setRadius(100)}
              >
                100m
              </button>
              <button
                className={radius == 1000 ? activeButton : notActiveButton}
                onClick={() => setRadius(1000)}
              >
                1km
              </button>
              <button
                className={radius == 10000 ? activeButton : notActiveButton}
                onClick={() => setRadius(10000)}
              >
                10km
              </button>
            </div>
            <div className="grid grid-cols-2 mt-2">
              <label className="mt-2 text-center font-bold">Höjd</label>
              <label className="mt-2 text-center font-bold">Outliers</label>
              <div className="grid justify-center mt-2">
                <Switch
                  checked={elevation}
                  onChange={setElevation}
                  className={`${
                    elevation ? "bg-blue-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      elevation ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                  />
                </Switch>
              </div>
              <div className="grid justify-center mt-2">
                <Switch
                  checked={percentile}
                  onChange={setPercentile}
                  className={`${
                    percentile ? "bg-blue-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      percentile ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                  />
                </Switch>
              </div>
            </div>
          </div>
          <hr className="mt-3" />
          <div className="time">
            <div className="time mt-3 grid grid-cols-2">
              <div className="mt-1 text-center font-semibold">Startdatum</div>
              <div className="mt-1 text-center font-semibold">Slutdatum</div>
              <button
                className="mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsStartOpen(!isStartOpen);
                  setIsEndOpen(false);
                }}
              >
                <span className="bg-transparent hover:bg-indigo-500 text-indigo-500 font-semibold hover:text-white py-2 px-4 border border-indigo-500 hover:border-transparent rounded">
                  {startDate.toISOString().substring(0, 7)}
                </span>
              </button>

              <button
                className="mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEndOpen(!isEndOpen);
                  setIsStartOpen(false);
                }}
              >
                <span className="bg-transparent hover:bg-indigo-500 text-indigo-500 font-semibold hover:text-white py-2 px-4 border border-indigo-500 hover:border-transparent rounded">
                  {endDate.toISOString().substring(0, 7)}
                </span>
              </button>
            </div>
            <div className="grid justify-center mt-3">
              {isStartOpen && (
                <div className="mt-2">
                  <div className="text-center">Välj Startdatum</div>
                  <DatePicker
                    className="w-full"
                    selected={startDate}
                    onChange={(date) => {
                      setIsStartOpen(!isStartOpen);
                      setStartDate(date);
                    }}
                    inline
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                  />
                </div>
              )}
              {isEndOpen && (
                <div className="mt-2">
                  <div className="text-center">Välj Slutdatum</div>
                  <DatePicker
                    className="mt-2"
                    selected={endDate}
                    onChange={(date) => {
                      setIsEndOpen(!isEndOpen);
                      setEndDate(date);
                    }}
                    inline
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                  />
                </div>
              )}
            </div>
            <hr className="mt-3" />
            <div className="colors mb-3">
              <div className="chooser grid grid-cols-4 mt-2">
                <div className="mt-2 font-bold">Jämför </div>
                <Select
                  className="col-span-3"
                  name="color"
                  defaultValue={colorElevationOptions[1]}
                  options={colorElevationOptions}
                  isSearchable={false}
                  onChange={(choice) => setColorField(choice.value)}
                />
              </div>
            </div>
            <hr className="mt-3" />
            <div className="mt-3 filter">
              <div>
                <Select
                  defaultValue={[selectOptions[0], selectOptions[1], selectOptions[2]]}
                  isMulti
                  isSearchable={false}
                  options={selectOptions}
                  onChange={(choice) => setDataFilters(choice.map((a) => a.value))}
                />
              </div>
            </div>
            <div className="mt-3 mb-1">
              <div className="text-center">Bostäder</div>
              <div className="text-center text-xl font-semibold">
                {numberOfObjects.toLocaleString().replace(/,/g, " ")}{" "}
              </div>
            </div>
          </div>

          <div className="mt-3">
            {loadDataOnLoad && (
              <div>
                <span className="mr-2">Ladda data direkt: </span>
                <input
                  type="checkbox"
                  checked={loadDataOnLoad}
                  onChange={() => {
                    setLoadDataOnLoad(!loadDataOnLoad);
                    setLoadDataChanged(true);
                  }}
                />
              </div>
            )}
            {loadDataChanged && <span>Kommer inte ladda automatiskt nästa gång!</span>}
          </div>
        </div>
      ) : null}
    </div>
  );
}
