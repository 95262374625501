import React from "react";

import Card from "./Card";
export default function CardArea({ objects }) {
  let properties = [];
  if (objects != null) {
    objects.forEach((object) => {
      properties.push(
        <Card key={object.key} property={object.property} relationId={object.relationId} />
      );
    });
  }

  return (
    <div>
      <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-1">{properties}</div>
      </div>
    </div>
  );
}
