import React from "react";
import useTitle from "../Util/title";

export default function PropertyDescription(props) {
  // Sources:
  // https://tailwindui.com/components/ecommerce/components/product-lists
  // https://tailwindui.com/components/application-ui/props.data-display/description-lists
  useTitle(props.data.street);
  return (
    <div className="container mx-auto">
      <h3
        className="font-medium leading-tight text-2xl mt-0 mb-2 mx-auto text-center"
        id="description"
      >
        {props.data.street}
      </h3>
      <div className="grid grid-cols-2 max-w-md pl-1 mx-auto">
        <div className="">Pris</div>
        <div className="">{props.data.price.toLocaleString().replace(/,/g, " ")} kr</div>
        <div className="">Rum</div>
        <div className="">{props.data.rooms}</div>
        <div className="">Boarea</div>
        <div className="">{props.data.sqm_area} m²</div>
        <div className="">Pris/m²</div>
        <div className="">{props.data.sqm_price.toLocaleString().replace(/,/g, " ")} kr/m²</div>
        <div className="">Driftskostnad</div>
        <div className="">
          {props.data.operating_cost.toLocaleString().replace(/,/g, " ")} kr/år
        </div>
        <div className="">Avgift</div>
        <div className="">{props.data.fee.toLocaleString().replace(/,/g, " ")} kr/mån</div>
        <div className="mt-2">Våning</div>
        <div className="mt-2">{props.data.story}</div>
        <div className="">Balkong</div>
        <div className="">{props.data.balcony}</div>
        <div className="">Område</div>
        <div className="">{props.data.area}</div>
        <div className="">Kommun</div>
        <div className="">{props.data.county}</div>
        <div className="">Typ</div>
        <div className="">{props.data.house_type}</div>
        <div className="">Byggår</div>
        <div className="">{props.data.construction_date}</div>

        <div className=" mt-2">Förening</div>
        <div className=" mt-2">{props.data.association_name}</div>
        <div className="">Äkta förening</div>
        <div className="">{props.data.association_is_pure === 1 ? "Ja" : "Nej"}</div>
        <div className="">Allabrf Rating</div>
        <div className="">
          {props.data.association_rating.toUpperCase()} ({props.data.association_rating_year})
        </div>
        <div className="">Antal lägenheter</div>
        <div className="">{props.data.association_total_units}</div>
        <div className="">Registreringsår</div>
        <div className="">{props.data.association_registered_at}</div>
      </div>
      <div className="grid grid-cols-3 max-w-sm px-1 py-1 mx-auto mt-3 text-center">
        <a href={props.data.hemnet_url} target="_blank" rel="noreferrer noopener">
          <button className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Hemnet
          </button>
        </a>

        <a href={props.data.realtor_url} target="_blank" rel="noreferrer noopener">
          <button className="bg-amber-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Mäklaren
          </button>
        </a>
        <a href={props.data.allabrf_url} target="_blank" rel="noreferrer noopener">
          <button className="bg-cyan-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Allabrf
          </button>
        </a>
      </div>
    </div>
  );
}
