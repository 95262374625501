import React, { useState } from "react";

import ImageViewer from "react-simple-image-viewer";

export default function PropertyImages({ imageUrls }) {
  // Sources:
  // https://tailwindui.com/components/ecommerce/components/product-lists
  // https://tailwindui.com/components/application-ui/data-display/description-lists
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  function openImageViewer(index) {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div>
      <h3 className="font-medium leading-tight text-2xl mt-0 mb-2 mx-auto text-center" id="images">
        Bilder
      </h3>
      <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1">
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="group relative">
              <div className="w-full bg-gray-200 overflow-hidden lg:hover:opacity-75 flex flex-column">
                <img
                  src={imageUrl}
                  onClick={() => openImageViewer(index)}
                  // alt={product.imageAlt}
                  className="w-full h-60 object-cover"
                  loading="lazy"
                />
              </div>
            </div>
          ))}
          {isViewerOpen && (
            <ImageViewer
              src={imageUrls}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
}
