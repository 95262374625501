import React from "react";
import { BsQuestionSquare } from "react-icons/bs";

export default function HelpText(props) {
  const [showGuide, setShowGuide] = React.useState(props.startGuideState);
  return (
    <div className="container mx-auto max-w-3xl px-1">
      <span className="flex cursor-pointer" onClick={() => setShowGuide((showGuide) => !showGuide)}>
        <BsQuestionSquare className="text-indigo-500 h-8 w-8" />
        <span className="px-2 font-bold">Hur gör jag?</span>
      </span>
      {showGuide ? (
        <div>
          <p> Bostadsbussen hjälper dig att hålla koll på bostäder från hemnet. </p>
          <ol className="list-decimal list-inside py-4">
            <li>
              Gå till{" "}
              <a
                className="text-indigo-600"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hemnet.se/"
              >
                hemnet.se
              </a>
            </li>
            <li>Hitta en annons som du vill spara</li>
            <li>Kopiera länken på annonsen från adressfältet</li>
            <li>Klistra in länken i rutan ovanför</li>
            <li>
              Tryck på <span className="font-bold">Lägg till</span>
            </li>
          </ol>
          <div>Nu kommer Bostadsbussen att hämta data från hemnet och spara i sin databas!</div>
        </div>
      ) : null}
    </div>
  );
}
