import React from "react";
import { Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { BsHouseDoor } from "react-icons/bs";
import { BsSquare } from "react-icons/bs";

export default function Properties(props) {
  let items = [];

  function propertyLink(hemnet_id) {
    let prop_link = "/dashboard/property/" + hemnet_id;
    return prop_link;
  }
  if (props.data.properties != null) {
    props.data.properties.forEach((property, index) => {
      items.push(
        <Marker key={index} position={[property.latitude, property.longitude]}>
          <Popup>
            <Link to={propertyLink(property.id)}>
              <div className="w-60">
                <div className="bg-gray-200 overflow-hidden lg:hover:opacity-75">
                  <img
                    className="object-cover h-40"
                    src={
                      "@expand" in property
                        ? property["@expand"].first_image.url
                        : "https://placekitten.com/g/600/400"
                    }
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="p-5">
                  <div className="grid grid-cols-12 text-sm text-slate-600 pb-2">
                    <div className="col-span-6 flex justify-start">
                      <BsSquare className="h-5 w-5" />
                      <span className="pl-2">{property.sqm_area} m²</span>
                    </div>
                    <div className="col-span-6 flex justify-end">
                      <BsHouseDoor className="h-5 w-5" />
                      <span className="pl-2">{property.rooms}</span>
                    </div>
                  </div>
                  <h5 className="mb-2 text-lg text-black font-bold overflow-hidden truncate w-full">
                    {property.street}
                  </h5>
                  <div className="text-lg text-black">
                    <div>{property.price.toLocaleString().replace(/,/g, " ")} kr</div>
                  </div>
                </div>
              </div>
            </Link>
          </Popup>
        </Marker>
      );
    });
  }
  return <div>{items}</div>;
}
