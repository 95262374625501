import React from "react";
import { Link } from "react-router-dom";
import useTitle from "../Util/title";
export default function Demo() {
  useTitle("Demo");
  return (
    <div className="container mx-auto flex">
      <div className="flex mx-auto">
        <div className="max-w-xl px-1 py-1">
          <p>
            Vill du inte skapa ett konto utan bara testa, så använd följande inloggningsuppgifter
            som en demo:
          </p>
          <p className="mt-5">
            <b>test@bostadsbussen.se</b>
          </p>
          <p>
            <b>testtest1</b>
          </p>
          <div className="rounded-md shadow mt-5">
            <Link
              to="/dashboard"
              className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
            >
              Logga in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
