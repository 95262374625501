import React from "react";
import { Link } from "react-router-dom";
import RemoveProperty from "./RemoveProperty";

// import "./index.css";

import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getSortedRowModel,
} from "@tanstack/react-table";
function propertyLink(hemnet_id) {
  let prop_link = "/dashboard/property/" + hemnet_id;
  return prop_link;
}
function formatToCrowns(number) {
  if (number != null) {
    return number.toLocaleString().replace(/,/g, " ") + " kr";
  } else return "";
}

export default function PropertyTable({ data }) {
  console.log(data);
  const defaultColumns = [
    {
      header: "Bostäder",
      columns: [
        {
          accessorKey: "street",
          cell: (info) => <Link to={propertyLink(info.row.original.id)}>{info.getValue()}</Link>,
          header: () => <span>Adress</span>,
        },
        { accessorKey: "rooms", cell: (info) => info.getValue(), header: () => <span>Rum</span> },
        {
          accessorKey: "sqm_area",
          header: () => <span>Boarea</span>,
          cell: (info) => info.getValue() + " m²",
        },
        {
          accessorKey: "price",
          header: () => <span>Utropspris</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "fee",
          header: () => <span>Avgift/mån</span>,
          cell: (info) => formatToCrowns(info.getValue()),
        },
        {
          accessorKey: "sqm_price",
          header: () => <span>Pris/m²</span>,
          cell: (info) => formatToCrowns(info.getValue()) + "/m²",
        },
        {
          accessorKey: "area",
          header: () => <span>Område</span>,
          cell: (info) => info.getValue(),
        },
        {
          accessorKey: "story",
          header: () => <span>Våning</span>,
          cell: (info) => info.getValue(),
        },
        {
          accessorKey: "balcony",
          header: () => <span>Balkong</span>,
          cell: (info) => info.getValue(),
        },
        {
          accessorKey: "association_rating",
          header: () => <span>Rating</span>,
          cell: (info) => info.getValue().toUpperCase(),
        },
        {
          accessorKey: "status",
          header: () => <span>Status</span>,
          cell: (info) => info.getValue(),
        },
        {
          accessorKey: "closing_price",
          header: () => <span>Slutpris</span>,
          cell: (info) => (info.getValue() > 0 ? formatToCrowns(info.getValue()) : "-"),
        },
        {
          accessorKey: "sqm_closing_price",
          header: () => <span>Slutpris/m²</span>,
          cell: (info) => (info.getValue() > 0 ? formatToCrowns(info.getValue()) + "/m²" : "-"),
        },
        {
          accessorKey: "relationId",
          header: () => <span>Ta bort</span>,
          cell: (info) => (
            <RemoveProperty
              id={info.getValue()}
              propertyId={info.row.original.id}
              street={info.row.original.street}
              url={
                "@expand" in info.row.original
                  ? info.row.original["@expand"].first_image.url
                  : "https://placekitten.com/g/600/400"
              }
              hemnet_url={info.row.original.hemnet_url}
            />
          ),
        },
      ],
    },
  ];
  const [columns] = [defaultColumns]; // TODO here i am hacking! Usestate correctly?
  const [sorting, setSorting] = React.useState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="container mx-auto py-4">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
