import React from "react";
import { Link } from "react-router-dom";
import useTitle from "../Util/title";
export default function NotFound() {
  useTitle("Bostadsbussen - finns inte");
  return (
    <div className="container mx-auto">
      <div className="px-4 mt-12">
        <main className="sm:mt-12 sm:px-6">
          <div className="sm:text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Denna sidan finns</span>{" "}
              <span className="block text-indigo-600 xl:inline">inte!</span>
            </h1>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <Link
                  to="/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  Gå tillbaka till startsidan!
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
