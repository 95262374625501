/// app.js
import React from "react";
// import { useEffect, useState } from "react";
import DeckGL from "@deck.gl/react";

// data load
// import { JSONLoader } from "@loaders.gl/json";
// import { load } from "@loaders.gl/core";

import { Map } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

import { getMapBoxToken } from "../App";
// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = getMapBoxToken();

const INITIAL_VIEW_STATE = {
  longitude: 15,
  latitude: 59,
  zoom: 5.2,
  minZoom: 4,
  maxZoom: 17,
  pitch: 40.5,
  bearing: 0,
};

function formatToSqm(number) {
  if (number != null) {
    return Math.floor(number).toLocaleString().replace(/,/g, " ") + " kr/m²";
  } else return "";
}
function formatToCrowns(number) {
  if (number != null) {
    return Math.floor(number).toLocaleString().replace(/,/g, " ") + " kr";
  } else return "";
}

function formatToLivingArea(number) {
  if (number != null) {
    return Math.floor(number) + " m²";
  }
}
function getTooltip({ object }) {
  if (!object) {
    return null;
  }
  const sqmPrice =
    object.points.reduce((avg, p) => Number(p.source.sqm_price) + avg, 0) / object.points.length;
  const livingArea =
    object.points.reduce((avg, p) => Number(p.source.living_area) + avg, 0) / object.points.length;
  const price =
    object.points.reduce((avg, p) => Number(p.source.price) + avg, 0) / object.points.length;
  const count = object.points.length;

  return `\
      Pris: ${formatToCrowns(price)}
      Storlek: ${formatToLivingArea(livingArea)}
      Pris/storlek: ${formatToSqm(sqmPrice)}
      Antal: ${count}
      `;
}

export default function SoldMap({ layers }) {
  return (
    <div className="mapBoxContainer">
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
        getTooltip={getTooltip}
      >
        <Map mapboxAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle="mapbox://styles/mapbox/dark-v10" />
      </DeckGL>
    </div>
  );
}
