import React from "react";
import useTitle from "../Util/title";
export default function About() {
  useTitle("Om");
  return (
    <div className="container mx-auto flex">
      <div className="flex mx-auto">
        <div className="max-w-xl px-1 py-1 ">
          <p className="font-bold">Hej! </p>
          <p className="mt-2">
            Jag byggde den här webbsidan inför ett bostadsköp! Det var mest för att jag tycker det
            är kul att bygga grejer 🤓
          </p>
          <p className="mt-2">
            Läs mer om vem jag är på min webbsida:{" "}
            <a className="underline text-blue-600 hover:text-blue-800" href="https://dahl.dev">
              dahl.dev
            </a>
          </p>
          <p className="mt-2">
            Är du teknisk och vill veta mer om arkitekturen och koden bakom sidan så går det att
            läsa om på min{" "}
            <a
              className="underline text-blue-600 hover:text-blue-800"
              href="https://blog.dahl.dev/posts/stringing-together-several-free-tiers-to-host-an-application-with-zero-cost/"
            >
              blog!
            </a>
          </p>
          <p className="mt-2">
            Vill du veta hur trafiken till sidan ser ut, kika på:{" "}
            <a
              className="underline text-blue-600 hover:text-blue-800"
              href=" https://analytics.umami.is/share/Z2FT1hKdacMtg8KF/bostadsbussen"
            >
              analytics dashboard
            </a>
          </p>
          <p className="mt-2">
            Stort tack till Love Lagerkvist på{" "}
            <a className="underline text-blue-600 hover:text-blue-800" href="https://motform.org">
              motform
            </a>{" "}
            för hjälp med design och UX!
          </p>

          <p className="mt-2">
            Vill du ha uppdateringar om bostadsbussen så följ mig på{" "}
            <a
              className="underline text-blue-600 hover:text-blue-800"
              href="https://twitter.com/aleda145"
            >
              twitter
            </a>
          </p>
          <p className="mt-2">
            Jag hoppas att du gillar webbsidan och att den hjälper dig i bostadsdjungeln!
          </p>
        </div>
      </div>
    </div>
  );
}
