import React from "react";

import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";

const notifyError = (errorMsg) => toast.error("Fel: " + errorMsg);
export default function AddProperty() {
  const client = useOutletContext();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(
    (newProperty) => {
      return client.send("/api/scrape", { method: "POST", body: newProperty });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allProperties");
        reset();
      },
      onError: (error) => {
        console.log(error);
        notifyError(error.data.message);
        reset();
      },
    }
  );

  // console.log(watch("example")); // watch input value by passing the name of it
  return (
    <div className="container mx-auto max-w-3xl px-1">
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit"  */}
      <form className="" onSubmit={handleSubmit(mutation.mutate)}>
        {/* register your input into the hook by invoking the "register" function */}
        <div className="py-2">
          <label htmlFor="hemnet-url" className="block text-sm font-medium text-gray-700">
            Hemnet Länk
          </label>
          <div className="grid grid-cols-7 sm:grid-cols-5 gap-2">
            <div className="col-span-5 sm:col-span-4">
              <input
                type="text"
                name="hemnet-url"
                id="hemnet-url"
                className=" focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm  "
                {...register("url", { required: true })}
              />
            </div>
            {mutation.isLoading || (
              <div className="col-span-2 sm:col-span-1">
                <button
                  type="submit"
                  className="bg-green-500 hover:bg-blue-700 text-white h-full font-bold w-full  px-4 "
                >
                  Lägg till
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mutation-response py-1">
          <div>{errors.url && <span>Du måste klistra in en hemnet-länk!</span>}</div>
          {mutation.isLoading && (
            <div className="">
              <span>Lägger till objekt...</span>
              <div className="">
                <span className="animation text-5xl">🚌</span>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
