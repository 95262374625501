import React from "react";
import soldMapPicture from "./sold_map.png";
import { Link } from "react-router-dom";
import DataPicture from "./house_data.jpg";
import QueryPicture from "./query.jpg";
import CharPicture from "./chart.jpg";
import useTitle from "../Util/title";

export default function SoldStatistics() {
  useTitle("Försäljningsstatistik");
  return (
    <div className="container mx-auto max-w-7xl">
      <div className="p-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        <div className="rounded overflow-hidden shadow-lg">
          <Link to="/sold/map">
            <img className="w-full" src={soldMapPicture} alt="Karta" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Karta</div>
              <p className="text-gray-700 text-base">
                Karta över slutpriser på Sverige. Ungefär 1 miljon bostadsrätter, villor och radhus
                finns att utforska!
              </p>
            </div>
          </Link>
        </div>

        <div className="rounded overflow-hidden shadow-lg">
          <Link to="/sold/charts">
            <img className="w-full" src={CharPicture} alt="Graf" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Grafer</div>
              <p className="text-gray-700 text-base">
                Grafer över slutpriser, spread, område och sådant!
              </p>
            </div>
          </Link>
        </div>
        <div className="rounded overflow-hidden shadow-lg">
          <Link to="/sold/data">
            <img className="w-full" src={DataPicture} alt="Data" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Data</div>
              <p className="text-gray-700 text-base">
                Vill du utforska datan själv? Finns att ladda ner direkt som JSON eller csv.
              </p>
            </div>
          </Link>
        </div>
        <div className="rounded overflow-hidden shadow-lg">
          <Link to="/sold/query">
            <img className="w-full" src={QueryPicture} alt="Query" />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">SQL</div>
              <p className="text-gray-700 text-base">Skriv SQL mot databasen direkt på webben</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
